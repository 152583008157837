import { useQuery } from '@apollo/client';
import EventHeader from 'components/EventHeader';
import EventAnimation from 'components/EventAnimation';
import Layout from 'components/Layout';
import React, { useEffect, useState } from 'react';
import { getOrder } from 'services/hasura';
import { useQueryParam, StringParam } from 'use-query-params';
import Row from 'components/Row';
import Col from 'components/Col';
import Cart from 'components/Cart';
import Text from 'components/Text';
import tw from 'twin.macro';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';

const CompleteWrapper = styled.div(() => [
  css`
    background: #e7eaee;
  `,
  tw`rounded-b-md`
]);

const Left = styled(Col)`
  ${tw`w-11/12 p-10 mx-auto`}
`;

const Right = styled.div`
  ${tw`w-full`}
`;
export default function complete() {
  const [id, setID] = useQueryParam('id', StringParam);

  const resp: { data; loading: boolean } = useQuery(getOrder(id));
  const [order, setOrder] = useState({});

  const [items, setItems] = useState([]);
  useEffect(() => {
    if (!resp.loading) {
      setOrder(resp.data.order);
      setItems(resp.data.order?.items);
    }
  }, [resp.loading]);

  useEffect(() => {
    if (resp.loading) return;
    if (order.length > 0) {
      console.log(order);
      // if (order.status !== 'paid') {
      // show modal and redirect to choos payment
      alert('failed');
      navigate(`/booking/choose-payment?id=${order.id}`);
      // }
    }
  }, [resp.loading, order]);
  return (
    <Layout>
      {resp.loading && order.status !== 'paid' ? (
        <EventAnimation />
      ) : (
        <Col>
          {order.event && <EventHeader event={order.event} />}
          <CompleteWrapper>
            <Row collapse>
              <Left spacing="sm" centered vCentered>
                <Row vCentered>
                  <Text spacing="sm" label="Your tickets have been booked" xlarge />
                </Row>
                <Text label="Please check your email for more details" medium />
              </Left>
              <Right>
                <Cart order={order} items={items} hideFooter day={order.day} days={order.days} />
              </Right>
            </Row>
          </CompleteWrapper>
        </Col>
      )}
    </Layout>
  );
}
